<template>
  <div v-bind:class="{ active:isActive }" class="decades-group">
    <div v-if="dateRangeValid('decade_1')" v-on:click="open(1, $event)"
         v-on:mouseover="openByHovered(1, $event)"
         :class="{init:(status===1 || infoInit===1)}"
         class="decade last-dec point_on">
      <div v-bind:style="{ backgroundColor: harvest.color }" class="bullet">
        <farmersPoints v-if="status === 1"  :farmers="farmers" :betweenDates="betweenDates" :decade="1"></farmersPoints>
        <div v-if="infoInit===1">
          <div class="v-info v-info-left">
            <p class="v-info-title">{{ $t('front.calendar_info_title')}}</p>
            <p class="v-info-desc">{{ $t('front.calendar_info_desc')}}<br/>{{ $t('front.calendar_info_desc2')}}</p>
          </div>
        </div>
      </div>
    </div>  <div v-else class="decade last-dec point_off"></div>
    <div v-if="dateRangeValid('decade_2')"
         v-on:click="open(2, $event)"
         v-on:mouseover="openByHovered(2, $event)"
         :class="{init:(status===2 || infoInit===2)}"
         class="decade last-dec point_on">
      <div v-bind:style="{ backgroundColor: harvest.color }" class="bullet">
        <farmersPoints v-if="status === 2" :farmers="farmers" :betweenDates="betweenDates" :decade="2"></farmersPoints>
        <div v-if="infoInit===2">
          <div class="v-info v-info-center">
            <p class="v-info-desc">{{ $t('front.calendar_info_desc')}}<br/>{{ $t('front.calendar_info_desc2')}}</p>
          </div>
        </div>
      </div>
    </div>  <div v-else class="decade last-dec point_off"></div>
    <div v-if="dateRangeValid('decade_3')" v-on:click="open(3, $event)"
         v-on:mouseover="openByHovered(3, $event)"
         :class="{init:(status===3 || infoInit===3)}"
         class="decade last-dec point_on">
      <div v-bind:style="{ backgroundColor: harvest.color }" class="bullet">
        <farmersPoints v-if="status === 3" :farmers="farmers" :betweenDates="betweenDates" :decade="3"></farmersPoints>
        <div v-if="infoInit===3">
          <div class="v-info v-info-right">
            <p class="v-info-title">{{ $t('front.calendar_info_title')}}</p>
            <p class="v-info-desc">{{ $t('front.calendar_info_desc')}}<br/>{{ $t('front.calendar_info_desc2')}}</p>
          </div>
        </div>
      </div>
    </div>  <div v-else class="decade last-dec point_off"></div>
  </div>
</template>
<script>
  import farmersPoints from './farmers';
  export default {
    props: [
      'harvest',
      'farmers',
      'betweenDates',
    ],
    components: {
      farmersPoints
    },
    data () {
      return {
        isActive: false,
        isActiveDec1Tmp: false,
        isActiveDec2Tmp: false,
        status: 0,
        current: 0,
        infoInit: 0
      };
    },
    watch: {
      status: function (value) {
        this.current = 1;
        this.$parent.$parent.$children.forEach((ch) => {
          let activeInAnotherGroup = ch.$children.filter(function (el) {
            return (el.status !== 0 || el.infoInit !== 0);
          });
          if (activeInAnotherGroup.length > 0) {
            activeInAnotherGroup.forEach(function (el) {
              if (el.status !== 0 && el.current !== 1) {
                el.close();
              }
            }.bind(this));
          }
        });

        if (!value) {
          $('body').removeClass('placeOpacity');
          $('.fruit-calendar.add-opacity').removeClass('add-opacity');
        }

        this.status = value;
      },
    },
    updated: function () {
      this.current = 0;
    },
    methods: {
      close () {
        this.status = 0;
        if (this.infoInit) {
          this.infoInit = 0;
        }
      },
      open: function (ev, event) {
        if (!$('.fruit-calendar').hasClass('add-opacity')) {
          this.status = ev;
          if (this.status && this.$mq !== 'desktop') {
            $('body').addClass('placeOpacity');
          }
        }
      },
      openByHovered: function (ev, event) {
        if (this.$mq === 'desktop') {
          this.status = ev;
          if (this.status && this.$mq !== 'desktop') {
            $('body').addClass('placeOpacity');
          }
        }
      },
      dateRangeValid (dec) {
        let ret = false;
        if (dec === 'decade_1') {
          this.farmers.forEach((val) => {
            if (val.dates.from <= this.betweenDates.point1
              && val.dates.to >= this.betweenDates.start) {
              this.isActive = true;
              ret = true;
            }
          });
          if (ret !== this.isActiveDec1Tmp) {
            this.isActiveDec1Tmp = ret;
          }
        }
        if (dec === 'decade_2') {
          this.farmers.forEach((val) => {
            if (val.dates.from <= this.betweenDates.point2
              && val.dates.to > this.betweenDates.point1) {
              this.isActive = true;
              this.isActiveDec2Tmp = true;
              ret = true;
            }
          });
          if (ret !== this.isActiveDec2Tmp) {
            this.isActiveDec2Tmp = ret;
          }
        }
        if (dec === 'decade_3') {
          this.farmers.forEach((val) => {
            if (val.dates.from <= this.betweenDates.point3
              && val.dates.to > this.betweenDates.point2) {
              this.isActive = true;
              ret = true;
            }
          });
          if (this.isActive && !ret && !this.isActiveDec2Tmp && !this.isActiveDec1Tmp) {
            this.isActive = false;
          }
          this.$emit('visibleRow', this.isActive);
        }
        if (ret === true && this.$mq!=='desktop') {
          let pp = $cookies.get('initiatedInfoPopap');
          if (!pp) {
            $cookies.set('initiatedInfoPopap', 1);
            if (this.isActiveDec1Tmp) {
              this.infoInit = 1;
            } else if (this.isActiveDec2Tmp) {
              this.infoInit = 2;
            } else { this.infoInit = 3; }

            $('.fruit-calendar').addClass('add-opacity');
          }
        }
        return ret;
      },
    },
  };
</script>

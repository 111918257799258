/* Scroll To */
$(document).ready(function () {
  $('.anchorLink').click(function (e) {
    e.preventDefault();

    anchorScroll($(this), $($(this).attr("href")), 100);
  });
});

function anchorScroll(this_obj, that_obj, base_speed) {
  let this_offset = this_obj.offset();
  let that_offset = that_obj.offset();
  let offset_diff = Math.abs(that_offset.top - this_offset.top);

  let speed = (offset_diff * base_speed) / 200;

  $('html,body').animate({
    scrollTop: that_offset.top
  }, speed);
}
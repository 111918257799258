<template>
  <div class="calendar-form">
    <transition name="fade">
      <table v-if="loaded" class="fruit-table-style">
        <tr v-if="$mq === 'desktop'" class="main-months-desktop-row main-month">
          <th></th>
          <th v-for="(calendarMonth, i) in calendarMonths.month" :key="i">{{ calendarMonth }}</th>
        </tr>
        <tr v-else class="main-months-mobile-row">
          <th :colspan="2">
            <div class="filter-container">
                <div class="filterByGroup dropDownFilter">
                  <span v-bind:class="{ opened : filterGroupOpened }" v-on:click="filterGroupDropDown" class="title-section">
                    {{ groupNameSelected }}
                    <span class="toggle-dd">
                      <span class="plus-ic"></span>
                    </span>
                  </span>
                  <ul v-bind:class="{ opened : filterGroupOpened }" class="list-section">
                    <li v-on:click="getFruitGroupById" :data-group-id="0" data-group-title="Filter">All</li>
                    <li v-on:click="getFruitGroupById" v-for="(item, index) in calendarItems"
                        :data-group-id="item.info.id" :data-group-title="item.info.name">{{ item.info.name }}</li>
                  </ul>
                </div>
                <div class="filterByMonth dropDownFilter">
                  <span v-bind:class="{ opened : filterOpened }" v-on:click="filterMonthDropDown" class="title-section">
                    {{ calendarMonths.month[monthSelected] }}
                    <span class="toggle-dd">
                      <span class="plus-ic"></span>
                    </span>
                  </span>
                  <ul v-bind:class="{ opened : filterOpened }" class="list-section">
                    <li v-on:click="filterByMonth" :class="{selected: monthSelected===i}" :data-month-key="i" v-for="(calendarMonth, i) in calendarMonths.month" :key="i">
                      {{ calendarMonth }}
                    </li>
                  </ul>
              </div>
            </div>
          </th>
        </tr>
        <template v-if="($mq === 'desktop') && (calendarItems.length > 0 && (groupId === 0 || groupId > 0 && calendarItem.info.id === groupId))" v-show="(calendarItems.length > 0 && (groupId === 0 || groupId > 0 && calendarItem.info.id === groupId))" v-for="calendarItem in calendarItems">
          <tr class="group-name">
            <td><span class="g-name">{{calendarItem.info.name}}</span></td>
            <td :colspan="getColumnsQty"></td>
          </tr>
          <template v-show="calendarItem.harvest.length > 0" v-for="calendarGroup in calendarItem.harvest">
            <VegetablesGroups :calendarGroup="calendarGroup" :calendarMonths="calendarMonths"></VegetablesGroups>
          </template>
        </template>
        <template v-if="($mq !== 'desktop') && (calendarItems.length > 0 && (groupId === 0 || groupId > 0 && calendarItem.info.id === groupId))" v-show="(calendarItems.length > 0 && (groupId === 0 || groupId > 0 && calendarItem.info.id === groupId))" v-for="calendarItem in calendarItems">
          <tr class="group-name">
            <td><span class="g-name">{{calendarItem.info.name}}</span></td>
            <td></td>
          </tr>
          <template v-show="calendarItem.harvest.length > 0" v-for="calendarGroup in calendarItem.harvest">
            <VegetablesGroups :groupSelection="groupId" :monthSelected="monthSelected" :calendarGroup="calendarGroup" :calendarMonths="calendarMonths"></VegetablesGroups>
          </template>
        </template>
      </table>

      <div v-else="loaded" class="loader-calendar">
        {{ $t('front.loader') }}
      </div>

    </transition>
  </div>
</template>

<script>
  import VegetablesGroups from './groups';
  export default {
    components: {VegetablesGroups},
    props: ['data', 'months', 'load', 'fruitGroupId', 'fruitGroupName'],
    data () {
      return {
        calendarItems: this.data,
        calendarMonths: this.months,
        loaded: this.load,
        groupId: this.fruitGroupId,
        monthSelected: 0,
        filterOpened: false,
        filterGroupOpened: false,
        groupNameSelected: this.fruitGroupName
      };
    },
    mounted: function () {
      let isIOS= this.iOSversion();
      if (isIOS) {
        document.addEventListener('touchstart', this.documentClick);
      } else {
        document.addEventListener('click', this.documentClick);
      }
    },
    beforeDestroy: function () {
      document.removeEventListener('click');
      document.removeEventListener('touchstart');
    },
    created () {
      this.fetch();
      // document.onclick = this.documentClick;
    },
    watch: {
      data () {
        this.calendarItems = this.data;
      },
      months () {
        this.calendarMonths = this.months;
      },
      load () {
        this.loaded = this.load;
      },
      fruitGroupId() {
        this.groupId = this.fruitGroupId;
      }
    },
    computed: {
      getColumnsQty: function () {
        return this.months.betweenPoints.length;
      }
    },
    methods: {
      iOSversion () {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
          if (!!window.indexedDB) { return 'iOS 8 and up'; }
          if (!!window.SpeechSynthesisUtterance) { return 'iOS 7'; }
          if (!!window.webkitAudioContext) { return 'iOS 6'; }
          if (!!window.matchMedia) { return 'iOS 5'; }
          if (!!window.history && 'pushState' in window.history) { return 'iOS 4'; }
          return 1;
        }

        return 0;
      },
      fetch () {
        return this.$emit('changed');
      },
      filterByMonth (event) {
        this.monthSelected = parseInt(event.currentTarget.dataset.monthKey);
        this.filterOpened = false;
        if (this.filterGroupOpened) {
          this.filterGroupOpened = false;
        }
      },
      filterMonthDropDown () {
        this.filterOpened = !this.filterOpened;
        if (this.filterGroupOpened) {
          this.filterGroupOpened = false;
        }
      },
      filterGroupDropDown () {
        this.filterGroupOpened = !this.filterGroupOpened;
        if (this.filterOpened) {
          this.filterOpened = false;
        }
      },
      getFruitGroupById (event) {
        this.groupNameSelected = event.currentTarget.dataset.groupTitle;
        this.groupId = parseInt(event.currentTarget.dataset.groupId);
        this.filterGroupOpened = !this.filterGroupOpened;
        if (this.filterOpened)  {
          this.filterOpened = false;
        }
      },
      documentClick(e) {
        let dropdown_selector = $('.decade.point_on');
        let opacity_container = $('.fruit-calendar.add-opacity');
        if (opacity_container.is(e.target)
          || dropdown_selector.is(e.target) && $('.fruit-calendar').hasClass('add-opacity')) {
          opacity_container.removeClass('add-opacity');
          this.$children.forEach((ch) => {
            let activeInAnotherGroup = ch.$children.filter(function (el) {
              return (el.status !== 0 || el.infoInit !== 0);
            });
            if (activeInAnotherGroup.length > 0) {
              activeInAnotherGroup.forEach(function (el) {
                if (el.status !== 0 || el.infoInit !== 0) {
                  el.close();
                }
              }.bind(this));
            }
          });
        } else if (!dropdown_selector.is(e.target) && dropdown_selector.has(e.target).length === 0) {
          this.$children.forEach((ch) => {
            let activeInAnotherGroup = ch.$children.filter(function (el) {
              return (el.status !== 0 || el.infoInit !== 0);
            });
            if (activeInAnotherGroup.length > 0) {
              activeInAnotherGroup.forEach(function (el) {
                if (el.status !== 0 && el.current !== 1) {
                  el.close();
                }
              }.bind(this));
            }
          });
        }
      }
    },
  };
</script>

<template>
  <div class="row">
    <div class="col-12  pl-lg-3 pl-0 pr-lg-3 pr-0">
      <div class="header-container">
        <div :class="{ row: ($mq === 'desktop')}">
          <div v-if="$mq === 'desktop'" class="col-4 pl-5">
            <div class="filterByHarvestGroup">
              <span v-bind:class="{ opened : opened }" v-on:click="filterDropDown" class="title-section">
                {{ fruitGroupName }}
                <span class="toggle-dd">
                  <span class="plus-ic"></span>
                </span>
              </span>
              <ul v-bind:class="{ opened : opened }" class="list-section">
                <li v-on:click="getFruitGroupById" :data-group-id="0" data-group-title="Filter">
                  {{$t('front.all')}}
                </li>
                <li v-on:click="getFruitGroupById" v-for="(item, index) in calendarItems"
                    :data-group-id="item.info.id" :data-group-title="item.info.name">{{ item.info.name }}</li>
              </ul>
            </div>
          </div>
          <div :class="[$mq !== 'desktop' ? '' : 'col-4']">
            <div class="nav-container">
              <ul class="years-navigation">
                <li>
                  <button v-on:click="prevYear" :class="{ disabled: !nearCurrentYears.prevYear}" class="btn-left btn-ch-year"></button>
                </li>
                <li>
                  <div v-if="loaded">
                  <span class="between-date">
                    {{ calendarMonths.year }}
                  </span>
                    <input type="hidden" v-model="year" value="" name="year">
                  </div>
                  <div v-else>
                    <span class="between-date">
                      {{ $t('front.loader') }}
                    </span>
                  </div>
                </li>
                <li>
                  <button v-on:click="nextYear" :class="{ disabled: !nearCurrentYears.nextYear}" class="btn-right btn-ch-year"></button>
                </li>
              </ul>
            </div>

            <button v-if="$mq !== 'desktop'" class="js-close-calendar d-lg-none d-xl-block"></button>
          </div>
          <div v-if="$mq === 'desktop'" class="col-4">
          </div>
        </div>
      </div>
      <CalendarRows :fruitGroupName="fruitGroupName" :data="calendarItems" :months="calendarMonths" :fruitGroupId="fruitGroupId" :load="loaded" @changed="fetch"></CalendarRows>
    </div>
  </div>
</template>

<script>
  import CalendarRows from './_partials/calendar';

  export default {
    components: {CalendarRows},
    props: ['farmer_id'],
    data () {
      return {
        year: '',
        old_year: '',
        calendarMonths: '',
        calendarItems: '',
        loaded: false,
        fruitGroupName: this.$t('front.filter'),
        fruitGroupId: 0,
        opened: false,
        nearCurrentYears: {}
      };
    },
    beforeCreate: function () {},
    mounted: function () {
      document.addEventListener('scroll', this.scrollMothFixed);
      window.addEventListener('resize', this.scrollMothFixedResize);
    },
    beforeDestroy: function () {
      document.removeEventListener('scroll');
      window.removeEventListener('resize');
    },
    methods: {
      fetch (year, group) {
        this.loaded = false;
        axios.get(this.url(year, group)).then(this.refresh);
      },
      url (year, group) {
        if (!year) {
          let query = this.year;
          year = query ? '&year=' + query : '';
        }
        if (!group) {
          let query = location.search.match(/group=(\d+)/);
          group = query ? '&group=' + query[1] : '';
        }

        let searchByFarmerId = '';
        if ((typeof this.farmer_id != 'undefined') && this.farmer_id.length) {
          searchByFarmerId = '&farmer=' + this.farmer_id;
        }
        // only when needed test initiated info popup in mobile v.
        // $cookies.remove('initiatedInfoPopap');
        // let localePrepare = (window.locale != 'ro') ? window.prefix + '/' : '';
        return ((window.prefix !== '') ? '/' + window.prefix : '') + `/seasons/filter?filter=1${year}${group}${searchByFarmerId}`;
      },
      refresh ({data}) {
        if ((typeof data.data.groups != 'undefined') && Object.keys(data.data.groups).length) {
          this.calendarItems = data.data.groups;
          this.calendarMonths = data.data.between;
          this.year = data.data.between.year;
          this.old_year = this.year;
          this.loaded = true;
          this.nearCurrentYears = data.data.nearCurrentYears
        } else {
          this.loaded = true;
          this.year = this.old_year;
        }
      },
      nextYear (event) {
        if (!$(event.currentTarget).hasClass('disabled')) {
          this.year++;
          this.fetch();
        }
      },
      prevYear (event) {
        if (!$(event.currentTarget).hasClass('disabled')) {
          --this.year;
          this.fetch();
        }
      },
      getFruitGroupById (event) {
        this.fruitGroupName = event.currentTarget.dataset.groupTitle;
        this.fruitGroupId = parseInt(event.currentTarget.dataset.groupId);
        this.opened = !this.opened;
      },
      filterDropDown (event) {
        this.opened = !this.opened;
      },
      scrollMothFixedResize: function() {
        let originalMonthObject = $('.fruit-table-style.clone-month-table');
        if (originalMonthObject.length) {
          originalMonthObject.css('maxWidth', $('#fruitContainer').outerWidth()-2);
        }
      },
      scrollMothFixed: function() {
        if (this.$mq !== 'desktop') {
          return;
        }

        let cntForm = $('#fruitContainer');
        let fixedDivTopOffset = cntForm.offset().top + 92;
        let fixedDivOffset = fixedDivTopOffset + cntForm.outerHeight(true) - 92 - 120;
        let currentScrollOffset = $(window).scrollTop();
        let widthFormOffset = cntForm.outerWidth();
        let cloneMonthObject = $('.clone-month-table');
        let originalMonthObject = $('.main-months-desktop-row.main-month');

        if (!originalMonthObject.length) {
          return;
        }

        if (currentScrollOffset >= fixedDivTopOffset && currentScrollOffset <= fixedDivOffset) {
          cntForm.addClass('fix');
          if (!cloneMonthObject.length) {
            let clonedMonth = originalMonthObject.clone();
            $('.calendar-form').prepend('<table style="max-width: ' + (parseInt(widthFormOffset)-2) + 'px;" class="fruit-table-style clone-month-table">' +
              '<tr class="main-months-desktop-row clone-fixed">' + clonedMonth.html() + '</tr></table>');
          }
          if (cloneMonthObject.is(":hidden")) {
            cloneMonthObject.show();
          }
          cloneMonthObject.css('top', '0');
        } else if(currentScrollOffset >= fixedDivOffset && fixedDivOffset+52 >= currentScrollOffset) {
          if (!cntForm.hasClass('fix')) {
            cntForm.addClass('fix');
          }
          if (cloneMonthObject.is(":hidden")) {
            cloneMonthObject.show();
          }
          cloneMonthObject.css('top', '-' + (52-(fixedDivOffset+52-currentScrollOffset))+ 'px');
        } else {
          cntForm.removeClass('fix');
          cloneMonthObject.hide().css('top', '0');
        }
      }
    }
  };
</script>

<template>
  <tr v-if="showRow">
    <td class="title-label">
      <span class="fruit-name">
        {{ calendarGroup.harvest.name }}
      </span>
    </td>
    <td v-if="($mq === 'desktop' || ($mq !== 'desktop' && monthSection === i))"
        v-for="(calendarPoint, i) in calendarMonths.betweenPoints" :key="i">
      <decadesPoints @visibleRow="visibleRow" :harvest="calendarGroup.harvest"
                     :farmers="calendarGroup.points" :betweenDates="calendarPoint"></decadesPoints>
    </td>
  </tr>
</template>

<script>
  import decadesPoints from './decades';
  export default {
    components: {
      decadesPoints
    },
    props: [
      'calendarGroup',
      'calendarMonths',
      'monthSelected',
      'groupSelection'
    ],
    data () {
      return {
        monthSection: this.monthSelected || 0,
        showRow: true
      };
    },
    watch: {
      monthSelected () {
        this.monthSection = this.monthSelected;
        this.showRow = true;
      },
      groupSelection () {
        this.showRow = true;
      }
    },
    methods: {
      visibleRow (dt) {
        if (this.$mq !== 'desktop') {
          this.showRow = dt;
        }
      },
      onStatusChange (el) {
        this.$children.forEach(function (ch) {
          if (ch.status) {
            ch.status = 0;
          }
        });
      }
    }
  };
</script>

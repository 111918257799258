<template>
    <div class="tour-coords js-farmer-map">
        <v-map ref="map" class="map" :zoom="zoom" :center="center" v-on:l-zoom="admin ? zoomChanged($event) : ''" v-on:l-click="admin ? addMarker($event) : ''">
            <v-tilelayer :url="url" :attribution="attribution"></v-tilelayer>
            <v-marker v-if="marker" :lat-lng="marker" :draggable="admin"
                      v-on:l-move="admin ? markerMoved($event) : ''" v-on:l-click="admin ? removeMarker() : ''">
            </v-marker>
        </v-map>
        <div v-if="marker!=='' && admin">
            <input type="hidden" name="map_coordinate[0]" :value="marker.lat">
            <input type="hidden" name="map_coordinate[1]" :value="marker.lng">
            <input type="hidden" name="map_coordinate[2]" :value="zoom">
        </div>
    </div>
</template>
<script>
    import Vue2Leaflet from 'vue2-leaflet';
    import LeafletSearch from 'leaflet-search';

    export default{
        props: ['coords', 'admin'],
        components: {
            'v-map': Vue2Leaflet.Map,
            'v-tilelayer': Vue2Leaflet.TileLayer,
            'v-marker': Vue2Leaflet.Marker,
        },
        data() {
            return {
                zoom: 13,
                center: [46.93432315590575, 28.93386840820313],
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                marker: '',
            }
        },
        mounted(){
            if(this.admin){
                this.$refs.map.mapObject.addControl(new L.Control.Search({
                    sourceData: this.googleGeocoding,
                    formatData: this.formatJSON,
                    filterData: this.filterResults,
                    autoType: true,
                    autoCollapse: true,
                    minLength: 2
                }));
            }
        },
        created(){
            if (this.coords !== '') {
                let coordinates = JSON.parse(this.coords);
                this.center = coordinates;
                this.zoom = parseInt(coordinates[2]);
                this.marker = L.latLng(coordinates[0], coordinates[1])
            }
        },
        methods: {
            addMarker: function (event) {
                this.marker = L.latLng(event.latlng);
            },
            markerMoved: function (event) {
                this.marker = L.latLng(event.latlng);
            },
            removeMarker: function () {
                this.marker = '';
            },
            zoomChanged: function(event){
                this.zoom = event.target.getZoom();
            },
            googleGeocoding(text, callResponse)
            {
                window.geocoder.getGeocode(text, callResponse);
            },

            formatJSON(rawjson)
            {
                var json = {},
                    key, loc;
                rawjson = JSON.parse(rawjson);
                rawjson['results'].forEach(item => {
                    key = item.formatted_address;

                    loc = L.latLng(item.geometry.location.lat, item.geometry.location.lng);

                    json[key] = loc;	//key,value format
                });
                return json;
            },
            filterResults(text, records){
                return records;
            }
        }
    }
</script>
<style>

    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet-search/src/leaflet-search.css";

    .tour-coords {
        height: 500px;
    }
    .tour-coords .map {
        z-index: 0
    }

    .leaflet-shadow-pane {
        display: none !important;
    }

</style>
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./scroll-to');
var $ = require('jquery');
require('fancybox')($);

import VueI18n from 'vue-i18n';
import VueMq from 'vue-mq';
import VueCookies from 'vue-cookies';

window.Vue = require('vue');
const i18n = new VueI18n({
    locale: window.locale || 'ro',
    messages: window.messages || {},
});

window.Masonry = require('./lib/masonry.pkgd.js');
var msnry;

if ($('#app').length) {
    Vue.component('map-coordinates', require('./components/map/MapCoordinates.vue').default);
    const appMap = new Vue({
        el: '#app',
        i18n,
    });

    $('.farmer-map-link').on('click', function() {
        setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 250);
    });
}

if ($('#fruitContainer').length) {
    Vue.component('fruit_calendar', require('./components/fruitCalendar/main.vue').default);
    Vue.use(VueCookies);
    Vue.use(VueMq, {
        breakpoints: {
            tablet: 992,
            desktop: Infinity,
        }
    });

    const app = new Vue({
        el: '#fruitContainer',
        i18n,
    });

    $(document).on('click', '.js-init-calendar', function() {
        $('.fruit-calendar').addClass('fixed').show();
    });

    $(document).on('click', '.js-close-calendar', function() {
        $('.fruit-calendar').removeClass('fixed').hide();
    });

    window.onresize = function(event) {
        let docWidth = document.body.clientWidth;
        if (docWidth > 991 && $('.fruit-calendar').hasClass('fixed')) {
            $('.fruit-calendar').removeClass('fixed');
        }
    };

    $(document).on('click', '.js-to-calendar', function(e) {
        e.preventDefault();
        let docWidth = document.body.clientWidth;
        if (docWidth < 992) {
            $('.fruit-calendar').addClass('fixed').show();
        } else {
            $(".fruit-calendar").removeClass('fixed');
            $('html, body').stop().animate({
                scrollTop: $(".fruit-calendar.atHome").offset().top
            }, 1200);
        }
        e.stopPropagation();
    });
}

$('.js-open-all-list').on('click', () => {
    $('.js-open-all-list').remove();
    $('.js-album-hide').show();

    var elem = document.querySelector('.masonry-gallery');
    msnry = new Masonry(elem, {
        itemSelector: '.masonry-item'
    });
    $('.masonry-gallery').css('opacity', '1');
});

$(function() {
    setTimeout(function() {
        if ($('.masonry-gallery').length) {
            var elem = document.querySelector('.masonry-gallery');
            msnry = new Masonry(elem, {
                itemSelector: '.masonry-item'
            });
            $('.masonry-gallery').css('opacity', '1');
        }
    }, 300);

    $('a.fancy').fancybox({
        'transitionIn': 'elastic',
        'transitionOut': 'elastic',
        'speedIn': 600,
        'speedOut': 200,
        'overlayShow': false
    });
});
<template>
  <div :class="orientDropDown" class="farmers-list">
    <div class="header-farmers-container">
      {{$t('front.farmers')}}
      <span  class="between-dates">{{ betweenTitle }}</span>
    </div>
    <div v-for="farmerItem in farmers" v-if="dateRangeValid(farmerItem, decade)" v-on:click="getFarmerRedirect(farmerItem.farmer.url)" class="farmer-item">
        <div class="farmer-logo-wrapper">
          <img v-if="farmerItem.farmer.logo" class="farmer-logo" :src=farmerItem.farmer.logo alt="">
          <span v-if="farmerItem.farmer.eco" class="vue-eco-block">
              <svg viewBox="0 0 23 23">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="eco-ico" transform="translate(11.556349, 11.556349) rotate(-315.000000) translate(-11.556349, -11.556349) translate(0.556349, 0.556349)">
                          <circle id="Oval-1" fill="#4EC457" cx="11" cy="11" r="11"></circle>
                          <path d="M15.7773182,10.6454475 C14.4430268,6.49431866 12.5157169,4.74647493 11.7003166,4.16386035 C11.3296801,3.94537988 10.8849163,3.94537988 10.5142798,4.16386035 C9.69887947,4.74647493 7.77156964,6.49431866 6.43727822,10.6454475 C5.1029868,14.7237496 7.10442393,16.2531128 8.66109725,16.9813811 C9.32824297,17.2726884 9.99538868,17.4911688 10.7366617,17.4911688 L10.7366617,15.5976714 L8.66109725,13.7770009 C8.51284265,13.6313472 8.51284265,13.4128668 8.66109725,13.2672131 C8.80935186,13.1215595 9.03173376,13.1215595 9.17998836,13.2672131 L10.7366617,14.6509228 L10.7366617,13.0487327 L9.03173376,11.5921962 C8.88347916,11.4465426 8.88347916,11.2280621 9.03173376,11.0824085 C9.17998836,10.9367548 9.40237027,10.9367548 9.55062487,11.0824085 L10.7366617,12.101984 L10.7366617,10.4997939 L9.62475217,9.5530452 C9.47649757,9.40739155 9.47649757,9.18891109 9.62475217,9.04325744 C9.77300677,8.8976038 9.99538868,8.8976038 10.1436433,9.04325744 L10.7366617,9.5530452 L10.7366617,8.38781604 C10.7366617,8.16933557 10.8849163,8.02368193 11.1072982,8.02368193 C11.3296801,8.02368193 11.4779347,8.16933557 11.4779347,8.38781604 L11.4779347,10.9367548 L12.2192077,10.2813134 C12.3674623,10.1356598 12.5898442,10.1356598 12.7380988,10.2813134 C12.8863534,10.4269671 12.8863534,10.6454475 12.7380988,10.7911012 L11.4779347,11.8835035 L11.4779347,13.4856936 L12.8863534,12.2476376 C13.034608,12.101984 13.2569899,12.101984 13.4052445,12.2476376 C13.5534991,12.3932913 13.5534991,12.6117717 13.4052445,12.7574254 L11.4779347,14.4324423 L11.4779347,17.5639956 C12.2192077,17.4911688 12.8863534,17.3455152 13.5534991,17.0542079 C15.1101725,16.2531128 17.0374823,14.7965764 15.7773182,10.6454475 L15.7773182,10.6454475 Z" id="Shape" fill="#FFFFFF"></path>
                      </g>
                  </g>
              </svg>
          </span>
        </div>
        <div class="farmer-info-container">
          <p class="farmer-title">
            {{farmerItem.farmer.name}}
          </p>
          <div class="farmer-drop-desc">
            <span class="farmer-address">
              {{ farmerItem.farmer.address }}
            </span>
            <a class="farmer-link" :href="farmerItem.farmer.url">
              {{ $t('front.more_info') }}
            </a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    props: ['farmers', 'betweenDates', 'decade'],
    data () {
      return {
        betweenTitle: '',
        orientDropDown: 'left'
      };
    },
    watch: {},
    updated () {
      let coord = this.getElementPosition($(this.$el).parent()[0]);
      let windowWidth = document.body.offsetWidth;
      if (coord.left + 350 > windowWidth) {
        this.orientDropDown = 'right';
      } else {
        this.orientDropDown = 'left';
      }
    },
    methods: {
      dateRangeValid (farmer, decade) {
        let ret = false;
        if (decade === 1) {
          if (farmer.dates.from <= this.betweenDates.point1
            && farmer.dates.to >= this.betweenDates.start) {
            this.betweenTitle = this.betweenDates.bulletFirstTitle;
            return true;
          }
        } else if (decade === 2) {
          if (farmer.dates.from <= this.betweenDates.point2
            && farmer.dates.to > this.betweenDates.point1) {
            this.betweenTitle = this.betweenDates.bulletSecondTitle;
            return true;
          }
        } else if (decade === 3) {
          if (farmer.dates.from <= this.betweenDates.point3
            && farmer.dates.to > this.betweenDates.point2) {
            this.betweenTitle = this.betweenDates.bulletThreeTitle;
            return true;
          }
        }
        return false;
      },
      getElementPosition: function (el) {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        };
      },
      getFarmerRedirect: function (ev) {
        if (ev.length)  {
          window.location.href = ev;
        }
      },
    },
  };
</script>
